import { Injectable, ElementRef, EventEmitter } from '@angular/core';
import { marked } from 'marked';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from './api.service';

declare var bootstrap: any;

@Injectable({
  providedIn: 'root',
})
export class ChatHelperService {
  constructor(private router: Router, private apiService: ApiService) {}

  //-------------------------------------
  // ---------- Scroll methods ----------
  //-------------------------------------

  isAtBottom(scrollContainer: ElementRef | null): boolean {
    if (!scrollContainer) return false;
    const threshold = 200;
    const position =
      scrollContainer.nativeElement.scrollTop +
      scrollContainer.nativeElement.offsetHeight;
    const height = scrollContainer.nativeElement.scrollHeight;

    return height - position <= threshold;
  }

  scrollToBottom(scrollContainer: ElementRef | null): void {
    console.log('scrollToBottom is called...');
    if (scrollContainer) {
      console.log('Found the scroll container...');
      setTimeout(() => {
        scrollContainer.nativeElement.scrollTop =
          scrollContainer.nativeElement.scrollHeight;
      }, 100);
    } else {
      console.log('NO scroll container...');
    }
  }

  //-------------------------------------
  // ------- Other helper methods -------
  //-------------------------------------

  triggerTooltips() {
    const tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    tooltipTriggerList.forEach((tooltipTriggerEl) => {
      new bootstrap.Tooltip(tooltipTriggerEl);
    });
  }

  startNewChat(): void {
    if (localStorage.getItem('authToken') == null) {
      this.router.navigate(['/chat']).then(() => {
        window.location.reload();
      });
    } else {
      this.apiService.resetCurrentMessages().subscribe(
        (response) => {
          this.router.navigate(['/chat']).then(() => {
            window.location.reload();
          });
        },
        (error) => {
          console.error('Error resetting messages:', error);
        }
      );
    }
  }
}
