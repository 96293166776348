import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ChatComponent } from './page_components/chat/chat.component';
import { HomeComponent } from './page_components/home/home.component';
import { ChatBoxComponent } from './common_components/chat-box/chat-box.component';
import { CardComponent } from './common_components/card/card.component';
import { HeaderComponent } from './common_components/header/header.component';
import { LoginComponent } from './page_components/login/login.component';
import { AuthService } from './services/auth.service';
import { PricingComponent } from './page_components/pricing/pricing.component';
import { FaqComponent } from './page_components/faq/faq.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './auth.interceptor';
import { AccountComponent } from './page_components/account/account.component';
import { DocumentChatComponent } from './page_components/document-chat/document-chat.component';
import { AngularSplitModule } from 'angular-split';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PopupComponent } from './common_components/popup/popup.component';
import { AccordionCourtsComponent } from './accordion-courts/accordion-courts.component';
import { DateRangeCourtsComponent } from './common_components/date-range-courts/date-range-courts.component';
import { FilterPopupComponent } from './common_components/filter-popup/filter-popup.component';
import { ChatBackupComponent } from './chat-backup/chat-backup.component';
import { CompareCasesComponent } from './page_components/compare-cases/compare-cases.component';
import { FooterComponent } from './common_components/footer/footer.component';
import { ComparePopupComponent } from './common_components/compare-popup/compare-popup.component';
import { AboutusComponent } from './page_components/aboutus/aboutus.component';
import { MessageComponent } from './common_components/message/message.component';
import { MessageListComponent } from './common_components/message-list/message-list.component';
import { FeedbackMessageComponent } from './common_components/feedback-message/feedback-message.component';
import { PaymentComponent } from './page_components/payment/payment.component';
import { PaymentPopupComponent } from './common_components/payment-popup/payment-popup.component';
import { TruncatePipe } from './pipes/truncate.pipe';
import { MyPlanComponent } from './page_components/my-plan/my-plan.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    CardComponent,
    HeaderComponent,
    LoginComponent,
    PricingComponent,
    FaqComponent,
    ChatComponent,
    ChatBoxComponent,
    AccountComponent,
    DocumentChatComponent,
    PopupComponent,
    AccordionCourtsComponent,
    DateRangeCourtsComponent,
    FilterPopupComponent,
    ChatBackupComponent,
    CompareCasesComponent,
    FooterComponent,
    ComparePopupComponent,
    AboutusComponent,
    MessageComponent,
    MessageListComponent,
    FeedbackMessageComponent,
    PaymentComponent,
    PaymentPopupComponent,
    TruncatePipe,
    MyPlanComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    AngularSplitModule,
    PdfViewerModule,
    ReactiveFormsModule,
  ],
  providers: [
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
