<div class="message-list-container">
  <app-message
    *ngFor="let message of messages"
    [sentMessage]="message.sentMessage"
    [resultFromBackend]="message.resultFromBackend"
    [loading]="message.loading"
    [uuid]="message.uuid"
    [messageType]="message.messageType"
    [stopped]="message.stopped"
  ></app-message>
</div>
