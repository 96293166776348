<div
  class="d-flex vh-100 justify-content-center align-items-center"
  style="height: calc(-88px + 100dvh - 66px) !important"
>
  <div class="card shadow-lg p-4" style="max-width: 400px; width: 100%">
    <h1 class="text-center mb-4">Subscription Page</h1>
    <div class="mb-3">
      <p class="mb-1"><strong>Plan:</strong> ${{ price }}/month</p>
      <p><strong>Currency:</strong> {{ currency }}</p>
    </div>
    <form (ngSubmit)="createCustomer()">
      <div id="card-element" class="mb-3"></div>
      <div id="card-errors" role="alert" class="text-danger mt-2">
        {{ cardError }}
      </div>
      <button
        type="submit"
        [disabled]="!cardComplete || cardError || isPaymentProcessing"
        class="btn btn-success w-100 mb-2"
      >
        {{ isPaymentProcessing ? "Processing..." : "Subscribe" }}
      </button>
      <button
        [routerLink]="'/pricing'"
        type="button"
        [disabled]="isPaymentProcessing"
        class="btn btn-danger w-100"
      >
        Cancel
      </button>
    </form>
  </div>
  <div *ngIf="showPaymentPopup">
    <app-payment-popup
      [message]="paymentPopupConfig?.message ?? 'Something went wrong.'"
      [countdown]="paymentPopupConfig?.countdown ?? null"
      [redirectPath]="paymentPopupConfig?.redirectPath ?? ''"
      [showRetryButton]="paymentPopupConfig?.showRetryButton ?? false"
    ></app-payment-popup>
  </div>
</div>
