import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { AuthService } from './services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    // 1. Capture the token from the URL if it's there (happens right after login)
    const token = route.queryParams['token'];
    if (token) {
      console.log('Token in URL (AuthGuard):', token);
      this.authService.setToken(token);

      this.router.navigate(['/chat']);
      return false;
    }

    // 2. Protect routes conditionally based on login status
    const loggedIn = this.authService.isLoggedIn();

    // Extract the base path from the URL (ignore query params)
    const baseUrl = state.url.split('?')[0];

    // 3. Protect the routes according to the logged in status
    const protectedRoutesLoggedIn = ['/pricing', '/login'];
    const protectedRoutesGuest = [
      '/account',
      '/payment',
      '/my-plan',
      '/compare-cases',
      '/document-chat',
    ];
    if (protectedRoutesGuest.includes(baseUrl) && !loggedIn) {
      this.router.navigate(['/login']);
      return false;
    }
    if (protectedRoutesLoggedIn.includes(baseUrl) && loggedIn) {
      this.router.navigate(['/account']);
    }

    // Allow access to other routes
    return true;
  }
}
