import {
  Component,
  ElementRef,
  OnInit,
  OnDestroy,
  Renderer2,
} from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  user: any = null;
  isLoggedIn = false;
  loginSubscription: Subscription | null = null;

  constructor(
    private renderer: Renderer2,
    private authService: AuthService,
    private router: Router,
    private elementRef: ElementRef
  ) {}

  ngOnInit(): void {
    // Subscribe to login state changes using the BehaviorSubject
    this.loginSubscription = this.authService.isLoggedIn$.subscribe(
      (loggedIn) => {
        this.isLoggedIn = loggedIn;

        if (this.isLoggedIn) {
          this.user = this.authService.getUserFromLocalStorage();
          console.log('(header) user: ', this.user);
        } else {
          this.user = null;
        }
      }
    );
  }

  useInitials() {
    this.user.picture = null; // Fallback to initials if the picture fails to load
  }

  // ----------------------------------
  // ------ USER SESSION METHODS ------
  // ----------------------------------

  updateUserData(): void {
    this.user = this.authService.getUserFromLocalStorage();
    console.log('(header) user: ', this.user);

    // If no picture is available, fall back to initials
    if (!this.user?.picture) {
      console.log('No profile picture available, using initials.');
    }
  }

  login() {
    this.router.navigate(['/login']);
  }

  logout() {
    this.authService.logout();
  }

  // Cleanup the subscription to prevent memory leaks
  ngOnDestroy(): void {
    if (this.loginSubscription) {
      this.loginSubscription.unsubscribe();
    }
  }
}
