<div class="navbar navbar-expand-lg fixed-top bg-body-tertiary">
  <div class="container-fluid">
    <!-- Navbar brand -->
    <div class="navbar-brand">
      <a
        class="navbar-brand d-flex align-items-center"
        [routerLink]="['/home']"
      >
        <img
          src="images/logo-no-bg.png"
          alt="Logo"
          width="30"
          height="30"
          class="d-inline-block align-text-top"
          style="margin-right: 5px"
        />
        <a style="font-weight: bold; color: aliceblue">DocketChat</a>
      </a>
    </div>

    <!-- Profile section-->
    <div *ngIf="isLoggedIn; else loginSection">
      <div
        class="dropdown ms-auto profile-section"
        role="button"
        data-bs-toggle="dropdown"
      >
        <ng-container *ngIf="user?.picture; else nameInitials">
          <img
            class="profile-picture"
            [src]="user.picture"
            alt="Profile Picture"
            loading="lazy"
            (error)="useInitials()"
          />
        </ng-container>
        <ng-template #nameInitials>
          <div class="profile-initial">
            {{ user?.displayName?.charAt(0) }}
          </div>
        </ng-template>

        <ul class="dropdown-menu dropdown-menu-end">
          <li>
            <a
              [routerLink]="'/account'"
              class="dropdown-item d-flex align-items-center"
            >
              <svg class="icon" style="width: 20px; height: 20px">
                <use xlink:href="icons.svg#account-icon"></use>
              </svg>
              Account
            </a>
          </li>

          <li><hr class="dropdown-divider" /></li>

          <li>
            <a
              class="dropdown-item d-flex align-items-center"
              (click)="logout()"
            >
              <svg class="icon" style="width: 20px; height: 20px">
                <use xlink:href="icons.svg#logout-icon"></use>
              </svg>
              Log out
            </a>
          </li>
        </ul>
      </div>
    </div>

    <!-- Login and Sign up buttons -->
    <ng-template #loginSection>
      <div class="ms-auto auth-buttons">
        <button type="button" (click)="login()" class="btn login-button">
          Sign In
        </button>
      </div>
    </ng-template>
  </div>
</div>
