import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient, private authService: AuthService) {}

  // ----------------------------------
  // --------- General Methods --------
  // ----------------------------------

  // Helper function to get the headers with the token
  private getHeaders(): HttpHeaders {
    const token = this.authService.getToken(); // Fetch the token
    return new HttpHeaders().set('Authorization', `Bearer ${token}`);
  }

  // Error handling.
  public handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    console.log(error);
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }

  // Stripe error handling.
  public handlePaymentError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    console.log(error);

    if (error.error instanceof ErrorEvent) {
      // Client-side error.
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side error.
      errorMessage =
        error.error?.error ||
        `Error Code: ${error.status}\nMessage: ${error.message}`;
    }

    return throwError(errorMessage);
  }

  // ----------------------------------
  // ----------- Chat Methods ---------
  // ----------------------------------

  postCompletionAPI(data: any): Observable<any> {
    console.log(data);
    const headers = this.getHeaders();
    return this.http
      .post<any>(`api/completionAPI`, data, { headers })
      .pipe(catchError(this.handleError));
  }

  postCompletionAPIdoc(data: any): Observable<any> {
    console.log(data);
    const headers = this.getHeaders();
    return this.http
      .post<any>(`api/completionAPIdoc`, data, { headers })
      .pipe(catchError(this.handleError));
  }

  postCompletionPublic(data: any): Observable<any> {
    console.log('public req is sent...');
    const headers = this.getHeaders();
    return this.http
      .post<any>(`api/completionPublic`, data, { headers })
      .pipe(catchError(this.handleError));
  }

  updateSessionMessages(previousMessages: string): Observable<any> {
    const headers = this.getHeaders();
    return this.http
      .post<any>(`api/updateSessionMessages`, { previousMessages }, { headers })
      .pipe(catchError(this.handleError));
  }

  getCurrentMessages(): Observable<any> {
    const headers = this.getHeaders();
    return this.http
      .get<any>(`api/getCurrentMessages`, { headers })
      .pipe(catchError(this.handleError));
  }

  getSearchInfo(): Observable<any> {
    const headers = this.getHeaders();
    return this.http
      .get<any>(`api/getSearchInfo`, { headers })
      .pipe(catchError(this.handleError));
  }

  resetCurrentMessages(): Observable<any> {
    const headers = this.getHeaders();
    return this.http
      .post<any>(`api/resetCurrentMessages`, {}, { headers })
      .pipe(catchError(this.handleError));
  }

  getPdfUrl(opinionId: number): Observable<HttpResponse<Blob>> {
    const headers = this.getHeaders();
    return this.http
      .get(`/api/pdfProxy/${opinionId}`, {
        headers,
        responseType: 'blob',
        observe: 'response',
      })
      .pipe(catchError(this.handleError));
  }

  getFormattedText(opinionId: number): Observable<string> {
    return this.http
      .get(`api/getFormattedText/${opinionId}`, {
        responseType: 'text',
      })
      .pipe(catchError(this.handleError));
  }

  compareCases(caseIds: number[]): Observable<any> {
    const headers = this.getHeaders();
    return this.http
      .post<any>(`/api/compareCases`, { opinionIds: caseIds }, { headers })
      .pipe(catchError(this.handleError));
  }

  // ----------------------------------
  // ---------- Court Methods ---------
  // ----------------------------------

  searchCourts(prompt: string): Observable<any[]> {
    return this.http
      .get<any[]>(`/api/searchCourt?prompt=${prompt}`)
      .pipe(catchError(this.handleError));
  }

  addCourt(courtId: string): Observable<any> {
    const headers = this.getHeaders();
    return this.http
      .post<any>(`/api/addCourt`, { courtId }, { headers })
      .pipe(catchError(this.handleError));
  }

  getUserCourts(): Observable<any[]> {
    const headers = this.getHeaders();
    return this.http
      .get<any[]>(`/api/getUserCourts`, { headers })
      .pipe(catchError(this.handleError));
  }

  removeCourt(courtId: string): Observable<any> {
    const headers = this.getHeaders();
    return this.http
      .delete<any>(`/api/removeCourt`, { headers, body: { courtId } })
      .pipe(catchError(this.handleError));
  }

  // ----------------------------------
  // --------- Payment Methods --------
  // ----------------------------------

  createPaymentIntent(data: {
    amount: number;
    currency: string;
  }): Observable<{ clientSecret: string }> {
    const headers = this.getHeaders();
    return this.http
      .post<{ clientSecret: string }>(`/api/createPaymentIntent`, data, {
        headers,
      })
      .pipe(catchError(this.handleError));
  }

  createCustomer(paymentMethodId: string): Observable<{ customerId: string }> {
    const headers = this.getHeaders();
    return this.http
      .post<{ customerId: string }>(
        `/api/createCustomer`,
        { paymentMethodId },
        { headers }
      )
      .pipe(catchError(this.handlePaymentError));
  }

  createSubscription(customerId: string): Observable<{
    clientSecret: string;
    paymentIntentStatus: string;
    requiresAction: boolean;
    nextAction: any | null;
  }> {
    const headers = this.getHeaders();
    return this.http
      .post<{
        clientSecret: string;
        paymentIntentStatus: string;
        requiresAction: boolean;
        nextAction: any | null;
      }>(`/api/createSubscription`, { customerId }, { headers })
      .pipe(catchError(this.handlePaymentError));
  }

  cancelSubscription(): Observable<any> {
    const headers = this.getHeaders();
    return this.http
      .post<any>(`/api/cancelSubscription`, {}, { headers })
      .pipe(catchError(this.handlePaymentError));
  }

  checkSubscription(): Observable<{
    success: boolean;
    isSubscribed: boolean;
    status?: string;
    cancelAtPeriodEnd?: boolean;
    accessUntil?: string | null;
    message?: string;
  }> {
    const headers = this.getHeaders();
    return this.http
      .get<{
        success: boolean;
        isSubscribed: boolean;
        status?: string;
        cancelAtPeriodEnd?: boolean;
        accessUntil?: string | null;
        message?: string;
      }>(`/api/checkSubscription`, { headers })
      .pipe(catchError(this.handlePaymentError));
  }
}
