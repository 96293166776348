<div
  class="fade-in custom-height container fade-in d-flex flex-column justify-content-center align-items-center vh-100"
>
  <!-- Welcome Section -->
  <div class="text-center mb-4">
    <h3 class="display-4" style="color: #c5c2c2">
      Welcome, {{ user.displayName }}!
    </h3>
  </div>

  <!-- Email Section -->
  <div class="account-container mx-auto mb-4">
    <div class="d-flex align-items-center">
      <svg class="me-3" width="24" height="24">
        <use xlink:href="icons.svg#email-icon"></use>
      </svg>
      <h5 class="m-0">{{ user.email }}</h5>
    </div>
  </div>

  <!-- Subscription Section -->
  <div class="account-container mx-auto">
    <h5 class="mb-3">Subscription Details</h5>
    <p *ngIf="!subscriptionStatus.hasSubscription">Plan: {{ "Free" }}<br /></p>
    <p
      *ngIf="
        subscriptionStatus.hasSubscription &&
        !subscriptionStatus.pendingCancellation
      "
    >
      Plan: {{ "Plus" }}<br />
      Next Payment Date:
      {{ subscriptionStatus.accessUntil | date : "longDate" }}
    </p>
    <p
      *ngIf="
        subscriptionStatus.hasSubscription &&
        subscriptionStatus.pendingCancellation
      "
    >
      Plan: {{ "Plus" }}<br />
      Expiration Date: {{ subscriptionStatus.accessUntil | date : "longDate" }}
    </p>
    <button class="btn btn-secondary" [routerLink]="'/my-plan'">
      Manage Subscription
    </button>
  </div>
</div>
