import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import {
  loadStripe,
  Stripe,
  StripeElements,
  StripeCardElement,
} from '@stripe/stripe-js';
import {
  SubscriptionService,
  SubscriptionStatus,
} from '../../services/subscription.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css'],
})
export class PaymentComponent implements OnInit {
  stripe!: Stripe | null;
  elements!: StripeElements | null;
  cardElement!: StripeCardElement | null;
  clientSecret!: string;
  isPaymentProcessing = false;
  showPaymentPopup: boolean = false;

  // Configuration for the popup
  paymentPopupConfig: {
    message: string;
    countdown: number | null;
    redirectPath: string;
    showRetryButton?: boolean;
  } | null = null;

  // Stripe validation state
  cardComplete: boolean = false;
  cardError: string | null = null;

  // Subscription details
  subscriptionStatus: SubscriptionStatus = {
    hasSubscription: false,
    accessUntil: null,
    pendingCancellation: false,
  };

  // Placeholder for plan details
  price = 49.99;
  currency = 'USD';

  constructor(
    private apiService: ApiService,
    private subscriptionService: SubscriptionService,
    private router: Router
  ) {}

  async ngOnInit() {
    this.setupStripe();
    this.checkSubscription();
  }

  async setupStripe() {
    try {
      this.stripe = await loadStripe(
        'pk_test_51QgmooJJMjAr4tj6ylZ57O0UzMIDa6wxsMIKHpBFBxDPsJBelS6lM1rV16Sv6mTZvGODaAHHtm5Gs58Dato4jgDt00Svz6bFKU'
      );

      if (!this.stripe) {
        throw new Error('Stripe.js could not be loaded.');
      }

      this.elements = this.stripe.elements();
      if (!this.elements) {
        throw new Error('Stripe Elements could not be initialized.');
      }

      this.cardElement = this.elements.create('card');
      this.cardElement.mount('#card-element');
      this.cardElement.on('change', (event) => {
        this.cardComplete = event.complete;
        this.cardError = event.error ? event.error.message : null;
      });
    } catch (error) {
      this.handleError(error, 'Failed to initialize Stripe.');
    }
  }

  checkSubscription() {
    this.subscriptionService.loadSubscriptionStatus().subscribe({
      next: (status) => {
        this.subscriptionStatus = status;
        if (status.hasSubscription) {
          this.togglePaymentPopup(
            'You already have an active subscription. Redirecting to your account...',
            '/account',
            5
          );
        }
      },
      error: (err) => {
        this.handleError(
          err,
          'An error occurred while checking your subscription.'
        );
      },
    });
  }

  createCustomer() {
    if (!this.cardComplete || this.cardError) {
      this.handleError(null, 'Card details are incomplete or invalid.');
      return;
    }

    this.isPaymentProcessing = true;

    this.stripe
      ?.createPaymentMethod({
        type: 'card',
        card: this.cardElement!,
      })
      .then((result) => {
        if (result.error) {
          this.handleError(result.error, 'Failed to create a payment method.');
          this.isPaymentProcessing = false;
        } else if (result.paymentMethod) {
          this.apiService.createCustomer(result.paymentMethod.id).subscribe({
            next: (response) => {
              this.createSubscription(response.customerId);
            },
            error: (err) => {
              console.log('Failed to create a customer.', err);
              this.handleError(err, 'Failed to create a customer.');
              this.isPaymentProcessing = false;
            },
          });
        }
      });
  }

  createSubscription(customerId: string) {
    this.apiService.createSubscription(customerId).subscribe({
      next: (response) => {
        this.clientSecret = response.clientSecret;

        if (response.requiresAction) {
          this.handle3DSecure(response.clientSecret);
        } else if (response.paymentIntentStatus === 'succeeded') {
          this.isPaymentProcessing = false;
          this.togglePaymentPopup(
            'Payment successful. Redirecting to chat...',
            '/chat',
            5
          );
        } else {
          this.completePayment();
        }
      },
      error: (err) => {
        this.handleError(err, 'Failed to create a subscription.');
        this.isPaymentProcessing = false;
      },
    });
  }

  async completePayment() {
    if (!this.stripe || !this.clientSecret || !this.cardElement) {
      this.handleError(
        null,
        'Stripe, clientSecret, or cardElement is not initialized.'
      );
      return;
    }

    const { error, paymentIntent } = await this.stripe.confirmCardPayment(
      this.clientSecret,
      {
        payment_method: { card: this.cardElement },
      }
    );

    this.isPaymentProcessing = false;

    if (error) {
      this.handleError(error, 'Payment failed.');
    } else if (paymentIntent.status === 'succeeded') {
      this.togglePaymentPopup(
        'Payment successful. Redirecting to chat...',
        '/chat',
        5
      );
    }
  }

  async handle3DSecure(clientSecret: string): Promise<void> {
    if (!this.stripe) {
      this.handleError(null, 'Stripe is not initialized.');
      return;
    }

    const { error, paymentIntent } = await this.stripe.confirmCardPayment(
      clientSecret
    );

    if (error) {
      console.log(error.message);
      this.handleError(error.message, '3D Secure authentication failed.');
      this.isPaymentProcessing = false;
    } else if (paymentIntent.status === 'succeeded') {
      this.isPaymentProcessing = false;
      this.togglePaymentPopup(
        'Payment successful. Redirecting to chat...',
        '/chat',
        5
      );
    }
  }

  togglePaymentPopup(
    message: string,
    redirectPath: string = '',
    countdown: number | null = null,
    showRetryButton: boolean = false
  ): void {
    this.showPaymentPopup = true;
    this.paymentPopupConfig = {
      message,
      countdown,
      redirectPath,
      showRetryButton,
    };
  }

  handleError(error: any, userMessage: string): void {
    console.error(error || userMessage);

    // const detailedMessage = error ? `${userMessage} (${error})` : userMessage;
    const detailedMessage = error || userMessage;

    this.togglePaymentPopup(detailedMessage, '', null, true); // Enable Retry
  }
}
