import { Component, Input, OnInit, Renderer2, ElementRef } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-compare-popup',
  templateUrl: './compare-popup.component.html',
  styleUrl: './compare-popup.component.css',
})
export class ComparePopupComponent {
  @Input() onHandleComparePopup!: () => void;
  private clickListener?: () => void;

  constructor(
    private meta: Meta,
    private renderer: Renderer2,
    private elRef: ElementRef
  ) {}

  ngOnInit(): void {
    document.addEventListener('wheel', this.preventZoom, { passive: false });
    document.addEventListener('keydown', this.preventZoomKeys, {
      passive: false,
    });

    this.clickListener = this.renderer.listen('document', 'click', (event) =>
      this.onClickOutside(event)
    );
  }

  ngOnDestroy(): void {
    document.removeEventListener('wheel', this.preventZoom);
    document.removeEventListener('keydown', this.preventZoomKeys);

    if (this.clickListener) {
      this.clickListener();
    }
  }

  preventZoom(event: WheelEvent): void {
    if (event.ctrlKey) {
      event.preventDefault();
    }
  }

  preventZoomKeys(event: KeyboardEvent): void {
    if (
      event.ctrlKey &&
      (event.key === '+' || event.key === '-' || event.key === '0')
    ) {
      event.preventDefault();
    }
  }

  onClickOutside(event: MouseEvent) {
    const popupContent =
      this.elRef.nativeElement.querySelector('.popup-content');
    const overlay = this.elRef.nativeElement.querySelector('.popup-overlay');

    // If the click happened outside the popup-content and on the overlay, trigger onCancel()
    if (overlay && !popupContent.contains(event.target)) {
      this.onHandleComparePopup();
    }
  }
}
