<!-- <div class="fade-in">
  <main class="main">
    <div class="container">
      <div
        class="custom-height row justify-content-center align-items-center vh-100"
      >
        <div class="col-md-4 d-none d-md-flex justify-content-center">
          <img src="images/logo-no-bg.png" alt="Logo" class="img-fluid" />
        </div>

        <div class="col-md-1 d-none d-md-block">
          <div class="divider"></div>
        </div>

        <div class="col-12 col-md-4">
          <div class="pricing-option btn" (click)="navigateToChat()">
            <h2 style="color: aliceblue">...Free Trial...</h2>
          </div>
          <hr class="hr-pricing" />
          <div class="pricing-option btn" (click)="navigateToPayment()">
            <h2 style="color: aliceblue">$24.99/mo</h2>
          </div>
        </div>
      </div>
    </div>
  </main>
</div> -->

<div class="fade-in container">
  <div
    class="custom-height row w-100 justify-content-center align-self-center vh-100"
  >
    <!-- Guest Tier -->
    <div hidden class="col-md-6 col-lg-4 mb-4 align-self-center">
      <div
        class="pricing-card card shadow-sm border-0"
        style="border-radius: 15px"
      >
        <div class="card-body text-center">
          <h5 class="card-title mb-4">Guest</h5>
          <h2 class="display-4 fw-bold">Free</h2>
          <p>Limited Access</p>
          <p class="mb-4">
            Experience basic features with limited functionality
          </p>
          <button [routerLink]="'/chat'" class="btn btn-secondary btn-lg mb-4">
            Chat Now
          </button>
          <ul class="text-start">
            <li class="mb-2">
              <i class="bi bi-check-circle-fill text-success"></i> Access to
              chat
            </li>
            <li class="mb-2">
              <i class="bi bi-x-circle-fill text-danger"></i> No message history
            </li>
            <li class="mb-2">
              <i class="bi bi-x-circle-fill text-danger"></i> Cannot compare
              cases
            </li>
            <li class="mb-2">
              <i class="bi bi-x-circle-fill text-danger"></i> No case-specific
              chat
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- Free Tier -->
    <div class="col-md-6 col-lg-4 mb-4 align-self-center">
      <div
        class="pricing-card card shadow-sm border-0"
        style="border-radius: 15px"
      >
        <div class="card-body text-center">
          <h5 class="card-title mb-4">Free</h5>
          <h2 class="display-4 fw-bold">Free</h2>
          <p>Limited Features</p>
          <p class="mb-4">Access to essential features with limited usage</p>
          <button [routerLink]="'/login'" class="btn btn-primary btn-lg mb-4">
            Log In
          </button>
          <ul class="text-start">
            <li class="mb-2">
              <i class="bi bi-check-circle-fill text-success"></i> Access to
              chat
            </li>
            <li hidden class="mb-2">
              <i class="bi bi-check-circle-fill text-success"></i> Access to
              message history
            </li>
            <li class="mb-2">
              <i class="bi bi-check-circle-fill text-success"></i> Compare cases
              (limited)
            </li>
            <li class="mb-2">
              <i class="bi bi-check-circle-fill text-success"></i> Chat about
              specific documents (limited)
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- Plus Tier -->
    <div class="col-md-6 col-lg-4 mb-4 align-self-center">
      <div
        class="pricing-card card shadow-sm border-0"
        style="border-radius: 15px"
      >
        <div class="card-body text-center">
          <h5 class="card-title mb-4">Plus</h5>
          <h2 class="display-4 fw-bold">$49.99</h2>
          <p>USD/month</p>
          <p class="mb-4">Unlock unlimited access to all features</p>
          <button [routerLink]="'/login'" class="btn btn-primary btn-lg mb-4">
            Log In to Upgrade
          </button>
          <ul class="text-start">
            <li class="mb-2">
              <i class="bi bi-check-circle-fill text-success"></i> Access to
              chat
            </li>
            <li class="mb-2">
              <i class="bi bi-check-circle-fill text-success"></i> Access to
              message history
            </li>
            <li class="mb-2">
              <i class="bi bi-check-circle-fill text-success"></i> Unlimited
              case comparisons
            </li>
            <li class="mb-2">
              <i class="bi bi-check-circle-fill text-success"></i> Chat about
              specific documents
            </li>
          </ul>
          <!-- <a href="#" class="d-block text-primary mt-3"
            >Manage my subscription</a
          > -->
        </div>
      </div>
    </div>
  </div>
</div>
