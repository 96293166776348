export class Message {
  sentMessage: string;
  resultFromBackend: string;
  loading: boolean;
  uuid: string;
  messageType: string;
  stopped: boolean;

  constructor(
    sentMessage: string,
    resultFromBackend: any,
    loading: boolean = true,
    uuid: string,
    messageType: string,
    stopped: boolean = false
  ) {
    this.sentMessage = sentMessage;
    this.resultFromBackend = resultFromBackend;
    this.loading = loading;
    this.uuid = uuid;
    this.messageType = messageType;
    this.stopped = stopped;
  }
}
