import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-payment-popup',
  templateUrl: './payment-popup.component.html',
  styleUrls: ['./payment-popup.component.css'],
})
export class PaymentPopupComponent implements OnInit {
  @Input() message: string = 'Processing...';
  @Input() countdown: number | null = null;
  @Input() redirectPath: string = '';
  @Input() confirmAction?: () => void;
  @Input() showRetryButton: boolean = false;

  private countdownIntervalId: any;

  constructor(private router: Router) {}

  ngOnInit(): void {
    if (this.countdown !== null) {
      this.startCountdown();
    }
  }

  ngOnDestroy(): void {
    if (this.countdownIntervalId) {
      clearInterval(this.countdownIntervalId);
    }
  }

  startCountdown(): void {
    this.countdownIntervalId = setInterval(() => {
      if (this.countdown !== null) {
        this.countdown--;
        if (this.countdown === 0) {
          this.clearCountdown();
          this.redirect();
        }
      }
    }, 1000);
  }

  redirect(): void {
    this.clearCountdown();
    if (this.redirectPath) {
      this.router.navigate([this.redirectPath]);
    }
  }

  clearCountdown(): void {
    if (this.countdownIntervalId) {
      clearInterval(this.countdownIntervalId);
      this.countdownIntervalId = null;
    }
  }

  // retry(): void {
  //   console.log('retrying...');
  //   this.router.navigate([this.router.url]);
  // }

  retry(): void {
    console.log('retrying...');
    this.router
      .navigate([this.router.url], { skipLocationChange: true })
      .then(() => {
        window.location.reload();
      });
  }
}
