import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import {
  SubscriptionService,
  SubscriptionStatus,
} from '../../services/subscription.service';

@Component({
  selector: 'app-my-plan',
  templateUrl: './my-plan.component.html',
  styleUrls: ['./my-plan.component.css'],
})
export class MyPlanComponent implements OnInit {
  subscriptionStatus: SubscriptionStatus = {
    hasSubscription: false,
    accessUntil: null,
    pendingCancellation: false,
  };

  showPopup: boolean = false;
  popupConfig: {
    message: string;
    countdown: number | null;
    redirectPath: string;
    confirmAction?: () => void;
  } | null = null;

  constructor(
    private subscriptionService: SubscriptionService,
    private apiService: ApiService
  ) {}

  ngOnInit(): void {
    // Fetch subscription status
    this.subscriptionService.loadSubscriptionStatus().subscribe({
      next: (status) => {
        this.subscriptionStatus = status;
        console.log('Subscription status (my-plan):', this.subscriptionStatus);
      },
      error: (err) => {
        console.error('Error fetching subscription status (my-plan):', err);
      },
    });
  }

  confirmDowngrade(): void {
    this.showPopup = true;
    this.popupConfig = {
      message: 'Are you sure you want to downgrade your plan?',
      countdown: null,
      redirectPath: '', // No redirection for confirmation popup
      confirmAction: () => this.cancelSubscription(),
    };
  }

  cancelSubscription(): void {
    this.showPopup = false;
    this.apiService.cancelSubscription().subscribe({
      next: (response) => {
        console.log('Subscription cancellation scheduled.');
        this.subscriptionStatus.pendingCancellation = true;
        this.popupConfig = {
          message: `Your subscription has been canceled and will expire on ${this.subscriptionStatus.accessUntil?.toLocaleDateString()}.`,
          countdown: 5,
          redirectPath: '/account',
        };
        this.showPopup = true;
      },
      error: (err) => {
        console.error('Error canceling subscription:', err);

        this.popupConfig = {
          message: 'Failed to cancel your subscription. Please try again.',
          countdown: null,
          redirectPath: '',
        };

        this.showPopup = true;
      },
    });
  }

  closePopup(): void {
    this.showPopup = false;
    this.popupConfig = null;
  }
}
