<div
  class="fade-in faq-container custom-height row justify-content-center align-items-center vh-100"
>
  <p style="color: aliceblue">
    <span><h3>About Us</h3> </span>
    <span><br /><br /></span>
    At DocketChat, we are a forward-thinking AI startup based in Florida, USA,
    dedicated to transforming the legal research process. Using cutting-edge
    AI-driven conversational technology, we empower legal professionals to
    access precise legal insights quickly and effortlessly, saving valuable time
    and resources.
    <span><br /><br /></span>

    What sets us apart? At DocketChat, your privacy is our priority. We
    guarantee that your research activities remain completely private. Unlike
    other platforms, we do not track, store, or sell your activity data to third
    parties. Your work stays your own, always.
    <span><br /><br /></span>

    Our mission is to make legal research seamless and accessible for all legal
    associates, enabling them to focus on delivering exceptional service.
    Whether you’re conducting research for complex cases or streamlining daily
    tasks, DocketChat is here to help.
    <span><br /><br /></span>
    We’re not just building a tool—we’re shaping the future of legal research.
    That’s why we’re actively seeking partnerships to elevate our platform and
    expand its impact. Together, we can redefine what’s possible in the legal
    industry.
    <span><br /><br /></span>

    Are you ready to embark on this journey with us? Contact us at 
    <span
      ><a href="mailto: info@docketchat.com">info&#64;docketchat.com</a></span
    > or calls us directly at <a href="tel:+18134600226">+1 813 460 0226</a>
    for assistance or to explore exciting partnership opportunities. Together, let’s revolutionize legal research and shape the future of the industry!
  </p>
</div>
