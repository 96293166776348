<div class="popup-overlay">
  <div class="popup-content">
    <p>{{ message }}</p>
    <p *ngIf="countdown !== null">Redirecting in {{ countdown }} seconds...</p>
    <br />
    <!-- Conditional redirect button -->
    <div class="popup-actions">
      <button *ngIf="redirectPath" (click)="redirect()">Go Now</button>
    </div>
    <!-- Conditional Confirm Button -->
    <button
      *ngIf="confirmAction"
      (click)="confirmAction()"
      class="btn btn-primary"
    >
      Confirm
    </button>
    <!-- Conditional Retry Button -->
    <button *ngIf="showRetryButton" (click)="retry()" class="btn btn-secondary">
      Retry
    </button>
  </div>
</div>
