import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, interval } from 'rxjs';

@Component({
  selector: 'app-feedback-message',
  templateUrl: './feedback-message.component.html',
  styleUrls: ['./feedback-message.component.css'],
})
export class FeedbackMessageComponent implements OnInit, OnDestroy {
  showFeedback: boolean = false;
  feedbackMessage: string = '';
  feedbackMessages: string[] = [
    'Checking cache...',
    'Processing your query...',
    'Searching for relevant cases...',
    'Finding the best matches...',
    'Refining the results...',
    'Checking additional sources...',
    'Analyzing case details...',
    'Compiling your results...',
    'Almost there...',
  ];
  feedbackSubscription!: Subscription;
  triggerFade: boolean = false;

  ngOnInit(): void {
    this.startFeedbackLoop();
  }

  startFeedbackLoop() {
    let index = 0;
    this.feedbackMessage = this.feedbackMessages[index];
    this.triggerFade = true;

    this.feedbackSubscription = interval(5000).subscribe(() => {
      this.triggerFade = false;
      setTimeout(() => {
        if (index < this.feedbackMessages.length - 1) {
          this.feedbackMessage = this.feedbackMessages[index];
          this.triggerFade = true;
          index++;
        } else {
          this.feedbackMessage =
            this.feedbackMessages[this.feedbackMessages.length - 1];
          this.triggerFade = true;
          this.feedbackSubscription.unsubscribe();
        }
      }, 100);
    });
  }

  stopFeedbackLoop() {
    if (this.feedbackSubscription) {
      this.feedbackSubscription.unsubscribe();
      this.triggerFade = false;
    }
  }

  ngOnDestroy(): void {
    this.stopFeedbackLoop();
  }
}
