import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ChatComponent } from './page_components/chat/chat.component';
import { HomeComponent } from './page_components/home/home.component';
import { LoginComponent } from './page_components/login/login.component';
import { PricingComponent } from './page_components/pricing/pricing.component';
import { FaqComponent } from './page_components/faq/faq.component';
import { AccountComponent } from './page_components/account/account.component';
import { DocumentChatComponent } from './page_components/document-chat/document-chat.component';
import { CompareCasesComponent } from './page_components/compare-cases/compare-cases.component';
import { AboutusComponent } from './page_components/aboutus/aboutus.component';
import { PaymentComponent } from './page_components/payment/payment.component';
import { MyPlanComponent } from './page_components/my-plan/my-plan.component';

import { AuthGuard } from './auth.guard';

const routes: Routes = [
  { path: 'chat', component: ChatComponent, canActivate: [AuthGuard] },
  { path: '', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'home', component: HomeComponent },
  { path: 'login', component: LoginComponent, canActivate: [AuthGuard] },
  { path: 'pricing', component: PricingComponent, canActivate: [AuthGuard] },
  { path: 'faq', component: FaqComponent },
  { path: 'about', component: AboutusComponent },
  { path: 'account', component: AccountComponent, canActivate: [AuthGuard] },
  {
    path: 'document-chat',
    component: DocumentChatComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'compare-cases',
    component: CompareCasesComponent,
    canActivate: [AuthGuard],
  },
  { path: 'payment', component: PaymentComponent, canActivate: [AuthGuard] },
  { path: 'my-plan', component: MyPlanComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
