import { Component, Renderer2, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-document-chat',
  templateUrl: './document-chat.component.html',
  styleUrls: ['./document-chat.component.css'],
})
export class DocumentChatComponent {
  src: string | undefined;
  opinionId: number | null = null;
  errorText: string = '';
  plainTextContent: string = '';
  showErrorMessage: boolean = false;
  showHtmlContent: boolean = false;
  showPlainText: boolean = false;
  htmlContent: SafeHtml | null = null;
  isLoading: boolean = true;

  constructor(
    private renderer: Renderer2,
    private apiService: ApiService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private router: Router
  ) {}

  ngOnInit(): void {
    const bodyElement = document.body;
    this.renderer.setStyle(bodyElement, 'display', 'block');

    const opinionIdParam = this.route.snapshot.queryParamMap.get('opinionId');
    if (opinionIdParam) {
      this.opinionId = Number(opinionIdParam);
      this.loadDocument(this.opinionId);
    } else {
      console.error('Opinion ID not provided in the URL.');
      this.router.navigate(['chat']);
    }
  }

  loadDocument(opinionId: number): void {
    this.apiService.getPdfUrl(opinionId).subscribe(
      (response) => {
        const blob = response.body;
        const contentType = response.headers.get('Content-Type');

        if (blob && contentType) {
          this.isLoading = false;
          if (contentType.includes('application/pdf')) {
            // Handle PDF format
            this.showErrorMessage = false;
            this.src = URL.createObjectURL(blob);
          } else if (
            contentType.includes('text/html') ||
            contentType.includes('text/plain')
          ) {
            // Handle HTML format
            this.showErrorMessage = false;
            blob.text().then((htmlContent) => {
              console.log(htmlContent);
              this.htmlContent =
                this.sanitizer.bypassSecurityTrustHtml(htmlContent);
              this.showHtmlContent = true;
            });
          } else {
            // If unsupported content type, display an error or log it.
            this.showErrorMessage = true;
            this.isLoading = false;
            this.errorText =
              'Unsupported document format. Please try another document.';
          }
        }
      },
      (error) => {
        if (error) {
          // Fetch plain text only if there's a 404 error as a fallback
          this.fetchFormattedText(opinionId);
        } else {
          this.showErrorMessage = true;
          this.isLoading = false;
          this.errorText = 'Error fetching document. Please try again.';
        }
      }
    );
  }

  fetchFormattedText(opinionId: number): void {
    this.apiService.getFormattedText(opinionId).subscribe(
      (formattedText) => {
        this.isLoading = false;
        console.log(formattedText);
        this.showErrorMessage = false;
        this.showHtmlContent = true;
        this.htmlContent =
          this.sanitizer.bypassSecurityTrustHtml(formattedText);
      },
      (error) => {
        this.showErrorMessage = true;
        this.isLoading = false;
        this.errorText = 'Document not found or not available in plain text.';
      }
    );
  }
}
