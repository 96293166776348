import {
  Component,
  Input,
  ElementRef,
  Output,
  EventEmitter,
  Renderer2,
} from '@angular/core';

@Component({
  selector: 'app-filter-popup',
  templateUrl: './filter-popup.component.html',
  styleUrl: './filter-popup.component.css',
})
export class FilterPopupComponent {
  @Input() onHandlePopup!: () => void;
  private clickListener?: () => void;

  selectedCourts: string[] = [];
  // dateRange: { from: number | null; to: number | null } = {
  //   from: null,
  //   to: null,
  // };

  selectedCourtsBackup: string[] = [];
  // dateRangeBackup: { from: number | null; to: number | null } = {
  //   from: null,
  //   to: null,
  // };

  @Output() selectedCourtsChanged = new EventEmitter<string[]>();
  // @Output() dateRangeChanged = new EventEmitter<{
  //   from: number | null;
  //   to: number | null;
  // }>();

  districts = [
    {
      name: 'Federal Appellate',
      sections: [
        {
          header: '',
          courts: [
            { id: 'scotus', label: 'Supreme Court', checked: false },
            { id: 'ca1', label: 'First Circuit', checked: false },
            { id: 'ca2', label: 'Second Circuit', checked: false },
            { id: 'ca3', label: 'Third Circuit', checked: false },
            { id: 'ca4', label: 'Fourth Circuit', checked: false },
            { id: 'ca5', label: 'Fifth Circuit', checked: false },
            { id: 'ca6', label: 'Sixth Circuit', checked: false },
            { id: 'ca7', label: 'Seventh Circuit', checked: false },
            { id: 'ca8', label: 'Eighth Circuit', checked: false },
            { id: 'ca9', label: 'Ninth Circuit', checked: false },
            { id: 'ca10', label: 'Tenth Circuit', checked: false },
            { id: 'ca11', label: 'Eleventh Circuit', checked: false },
            { id: 'cadc', label: 'D.C. Circuit', checked: false },
            { id: 'cafc', label: 'Federal Circuit', checked: false },
          ],
        },
      ],
    },
    {
      name: 'Federal District',
      sections: [
        {
          header: 'Miscellaneous Courts',
          courts: [
            { id: 'dcd', label: 'District of Columbia', checked: false },
            { id: 'almd', label: 'M.D. Alabama', checked: false },
            { id: 'alnd', label: 'N.D. Alabama', checked: false },
            { id: 'alsd', label: 'S.D. Alabama', checked: false },
            { id: 'akd', label: 'D. Alaska', checked: false },
            { id: 'azd', label: 'D. Arizona', checked: false },
            { id: 'ared', label: 'E.D. Arkansas', checked: false },
            { id: 'arwd', label: 'W.D. Arkansas', checked: false },
            { id: 'cacd', label: 'C.D. California', checked: false },
            { id: 'caed', label: 'E.D. California', checked: false },
            { id: 'cand', label: 'N.D. California', checked: false },
            { id: 'casd', label: 'S.D. California', checked: false },
            { id: 'cod', label: 'D. Colorado', checked: false },
            { id: 'ctd', label: 'D. Connecticut', checked: false },
            { id: 'ded', label: 'D. Delaware', checked: false },
            { id: 'flmd', label: 'M.D. Florida', checked: false },
            { id: 'flnd', label: 'N.D. Florida', checked: false },
            { id: 'flsd', label: 'S.D. Florida', checked: false },
            { id: 'gamd', label: 'M.D. Georgia', checked: false },
            { id: 'gand', label: 'N.D. Georgia', checked: false },
            { id: 'gasd', label: 'S.D. Georgia', checked: false },
            { id: 'hid', label: 'D. Hawaii', checked: false },
            { id: 'idd', label: 'D. Idaho', checked: false },
            { id: 'ilcd', label: 'C.D. Illinois', checked: false },
            { id: 'ilnd', label: 'N.D. Illinois', checked: false },
            { id: 'ilsd', label: 'S.D. Illinois', checked: false },
            { id: 'innd', label: 'N.D. Indiana', checked: false },
            { id: 'insd', label: 'S.D. Indiana', checked: false },
            { id: 'iand', label: 'N.D. Iowa', checked: false },
            { id: 'iasd', label: 'S.D. Iowa', checked: false },
            { id: 'ksd', label: 'D. Kansas', checked: false },
            { id: 'kyed', label: 'E.D. Kentucky', checked: false },
            { id: 'kywd', label: 'W.D. Kentucky', checked: false },
            { id: 'laed', label: 'E.D. Louisiana', checked: false },
            { id: 'lamd', label: 'M.D. Louisiana', checked: false },
            { id: 'lawd', label: 'W.D. Louisiana', checked: false },
            { id: 'med', label: 'D. Maine', checked: false },
            { id: 'mdd', label: 'D. Maryland', checked: false },
            { id: 'mad', label: 'D. Massachusetts', checked: false },
            { id: 'mied', label: 'E.D. Michigan', checked: false },
            { id: 'miwd', label: 'W.D. Michigan', checked: false },
            { id: 'mnd', label: 'D. Minnesota', checked: false },
            { id: 'msnd', label: 'N.D. Mississippi', checked: false },
            { id: 'mssd', label: 'S.D. Mississippi', checked: false },
            { id: 'moed', label: 'E.D. Missouri', checked: false },
            { id: 'mowd', label: 'W.D. Missouri', checked: false },
            { id: 'mtd', label: 'D. Montana', checked: false },
            { id: 'ned', label: 'D. Nebraska', checked: false },
            { id: 'nvd', label: 'D. Nevada', checked: false },
            { id: 'nhd', label: 'D. New Hampshire', checked: false },
            { id: 'njd', label: 'D. New Jersey', checked: false },
            { id: 'nmd', label: 'D. New Mexico', checked: false },
            { id: 'nyed', label: 'E.D. New York', checked: false },
            { id: 'nynd', label: 'N.D. New York', checked: false },
            { id: 'nysd', label: 'S.D. New York', checked: false },
            { id: 'nywd', label: 'W.D. New York', checked: false },
            { id: 'nced', label: 'E.D. North Carolina', checked: false },
            { id: 'ncmd', label: 'M.D. North Carolina', checked: false },
            { id: 'ncwd', label: 'W.D. North Carolina', checked: false },
            { id: 'ndd', label: 'D. North Dakota', checked: false },
            { id: 'ohnd', label: 'N.D. Ohio', checked: false },
            { id: 'ohsd', label: 'S.D. Ohio', checked: false },
            { id: 'oked', label: 'E.D. Oklahoma', checked: false },
            { id: 'oknd', label: 'N.D. Oklahoma', checked: false },
            { id: 'okwd', label: 'W.D. Oklahoma', checked: false },
            { id: 'ord', label: 'D. Oregon', checked: false },
            { id: 'paed', label: 'E.D. Pennsylvania', checked: false },
            { id: 'pamd', label: 'M.D. Pennsylvania', checked: false },
            { id: 'pawd', label: 'W.D. Pennsylvania', checked: false },
            { id: 'rid', label: 'D. Rhode Island', checked: false },
            { id: 'scd', label: 'D. South Carolina', checked: false },
            { id: 'sdd', label: 'D. South Dakota', checked: false },
            { id: 'tned', label: 'E.D. Tennessee', checked: false },
            { id: 'tnmd', label: 'M.D. Tennessee', checked: false },
            { id: 'tnwd', label: 'W.D. Tennessee', checked: false },
            { id: 'txed', label: 'E.D. Texas', checked: false },
            { id: 'txnd', label: 'N.D. Texas', checked: false },
            { id: 'txsd', label: 'S.D. Texas', checked: false },
            { id: 'txwd', label: 'W.D. Texas', checked: false },
            { id: 'utd', label: 'D. Utah', checked: false },
            { id: 'vtd', label: 'D. Vermont', checked: false },
            { id: 'vaed', label: 'E.D. Virginia', checked: false },
            { id: 'vawd', label: 'W.D. Virginia', checked: false },
            { id: 'waed', label: 'E.D. Washington', checked: false },
            { id: 'wawd', label: 'W.D. Washington', checked: false },
            { id: 'wvnd', label: 'N.D. West Virginia', checked: false },
            { id: 'wvsd', label: 'S.D. West Virginia', checked: false },
            { id: 'wied', label: 'E.D. Wisconsin', checked: false },
            { id: 'wiwd', label: 'W.D. Wisconsin', checked: false },
            { id: 'wyd', label: 'D. Wyoming', checked: false },
            { id: 'gud', label: 'D. Guam', checked: false },
            { id: 'nmid', label: 'Northern Mariana Islands', checked: false },
            { id: 'prd', label: 'D. Puerto Rico', checked: false },
            { id: 'vid', label: 'Virgin Islands', checked: false },
          ],
        },
        {
          header: 'Historical Courts',
          courts: [
            {
              id: 'californiad',
              label: 'D. California (1886)',
              checked: false,
            },
            {
              id: 'illinoised',
              label: 'E.D. Illinois (1978)',
              checked: false,
            },
            { id: 'illinoisd', label: 'D. Illinois (1855)', checked: false },
            { id: 'indianad', label: 'D. Indiana (1928)', checked: false },
            {
              id: 'orld',
              label: 'Orleans District Court (1812)',
              checked: false,
            },
            { id: 'ohiod', label: 'D. Ohio (1855)', checked: false },
            {
              id: 'pennsylvaniad',
              label: 'D. Pennsylvania (1818)',
              checked: false,
            },
            {
              id: 'southcarolinaed',
              label: 'E.D. South Carolina (1965)',
              checked: false,
            },
            {
              id: 'southcarolinawd',
              label: 'W.D. South Carolina (1965)',
              checked: false,
            },
            {
              id: 'tennessed',
              label: 'D. Tennessee (1839)',
              checked: false,
            },
            {
              id: 'canalzoned',
              label: 'District Court, Canal Zone (1982)',
              checked: false,
            },
          ],
        },
      ],
    },
    {
      name: 'Bankruptcy',
      sections: [
        {
          header: 'Bankruptcy Appellate Panels',
          courts: [
            { id: 'bap1', label: 'First Circuit', checked: false },
            { id: 'bap2', label: 'Second Circuit', checked: false },
            { id: 'bap6', label: 'Sixth Circuit', checked: false },
            { id: 'bap8', label: 'Eighth Circuit', checked: false },
            { id: 'bap9', label: 'Ninth Circuit', checked: false },
            { id: 'bap10', label: 'Tenth Circuit', checked: false },
            { id: 'bapme', label: 'D. Maine', checked: false },
            { id: 'bapma', label: 'Massachusetts', checked: false },
          ],
        },
        {
          header: 'Bankruptcy District Courts',
          courts: [
            { id: 'almb', label: 'M.D. Alabama', checked: false },
            { id: 'alnb', label: 'N.D. Alabama', checked: false },
            { id: 'alsb', label: 'S.D. Alabama', checked: false },
            { id: 'akb', label: 'D. Alaska', checked: false },
            { id: 'arb', label: 'D. Arizona', checked: false },
            { id: 'areb', label: 'E.D. Arkansas', checked: false },
            { id: 'arwb', label: 'W.D. Arkansas', checked: false },
            { id: 'cacb', label: 'C.D. California', checked: false },
            { id: 'caeb', label: 'E.D. California', checked: false },
            { id: 'canb', label: 'N.D. California', checked: false },
            { id: 'casb', label: 'S.D. California', checked: false },
            { id: 'cob', label: 'D. Colorado', checked: false },
            { id: 'ctb', label: 'D. Connecticut', checked: false },
            { id: 'deb', label: 'D. Delaware', checked: false },
            { id: 'dcb', label: 'District of Columbia', checked: false },
            { id: 'flmb', label: 'M.D. Florida', checked: false },
            { id: 'flnb', label: 'N.D. Florida', checked: false },
            { id: 'flsb', label: 'S.D. Florida', checked: false },
            { id: 'gamb', label: 'M.D. Georgia', checked: false },
            { id: 'ganb', label: 'N.D. Georgia', checked: false },
            { id: 'gasb', label: 'S.D. Georgia', checked: false },
            { id: 'hib', label: 'D. Hawaii', checked: false },
            { id: 'idb', label: 'D. Idaho', checked: false },
            { id: 'ilcb', label: 'C.D. Illinois', checked: false },
            { id: 'ilnb', label: 'N.D. Illinois', checked: false },
            { id: 'ilsb', label: 'S.D. Illinois', checked: false },
            { id: 'innb', label: 'N.D. Indiana', checked: false },
            { id: 'insb', label: 'S.D. Indiana', checked: false },
            { id: 'ianb', label: 'N.D. Iowa', checked: false },
            { id: 'iasb', label: 'S.D. Iowa', checked: false },
            { id: 'ksb', label: 'D. Kansas', checked: false },
            { id: 'kyeb', label: 'E.D. Kentucky', checked: false },
            { id: 'kywb', label: 'W.D. Kentucky', checked: false },
            { id: 'laeb', label: 'E.D. Louisiana', checked: false },
            { id: 'lamd', label: 'M.D. Louisiana', checked: false },
            { id: 'lawb', label: 'W.D. Louisiana', checked: false },
            { id: 'meb', label: 'D. Maine', checked: false },
            { id: 'mdb', label: 'D. Maryland', checked: false },
            { id: 'mab', label: 'D. Massachusetts', checked: false },
            { id: 'mieb', label: 'E.D. Michigan', checked: false },
            { id: 'miwb', label: 'W.D. Michigan', checked: false },
            { id: 'mnb', label: 'D. Minnesota', checked: false },
            { id: 'msnb', label: 'N.D. Mississippi', checked: false },
            { id: 'mssb', label: 'S.D. Mississippi', checked: false },
            { id: 'moeb', label: 'E.D. Missouri', checked: false },
            { id: 'mowb', label: 'W.D. Missouri', checked: false },
            { id: 'mtb', label: 'D. Montana', checked: false },
            { id: 'nebraskab', label: 'D. Nebraska', checked: false },
            { id: 'nvb', label: 'D. Nevada', checked: false },
            { id: 'nhb', label: 'D. New Hampshire', checked: false },
            { id: 'njb', label: 'D. New Jersey', checked: false },
            { id: 'nmb', label: 'D. New Mexico', checked: false },
            { id: 'nyeb', label: 'E.D. New York', checked: false },
            { id: 'nynb', label: 'N.D. New York', checked: false },
            { id: 'nysb', label: 'S.D. New York', checked: false },
            { id: 'nywb', label: 'W.D. New York', checked: false },
            { id: 'nceb', label: 'E.D. North Carolina', checked: false },
            { id: 'ncmb', label: 'M.D. North Carolina', checked: false },
            { id: 'ncwb', label: 'W.D. North Carolina', checked: false },
            { id: 'ndb', label: 'D. North Dakota', checked: false },
            { id: 'ohnb', label: 'N.D. Ohio', checked: false },
            { id: 'ohsb', label: 'S.D. Ohio', checked: false },
            { id: 'okeb', label: 'E.D. Oklahoma', checked: false },
            { id: 'oknb', label: 'N.D. Oklahoma', checked: false },
            { id: 'okwb', label: 'W.D. Oklahoma', checked: false },
            { id: 'orb', label: 'D. Oregon', checked: false },
            { id: 'paeb', label: 'E.D. Pennsylvania', checked: false },
            { id: 'pamb', label: 'M.D. Pennsylvania', checked: false },
            { id: 'pawb', label: 'W.D. Pennsylvania', checked: false },
            { id: 'rib', label: 'D. Rhode Island', checked: false },
            { id: 'scb', label: 'D. South Carolina', checked: false },
            { id: 'sdb', label: 'D. South Dakota', checked: false },
            { id: 'tneb', label: 'E.D. Tennessee', checked: false },
            { id: 'tnmb', label: 'M.D. Tennessee', checked: false },
            { id: 'tnwb', label: 'W.D. Tennessee', checked: false },
            {
              id: 'tennesseeb',
              label: 'D. Tennessee (Terminated 1801)',
              checked: false,
            },
            { id: 'txeb', label: 'E.D. Texas', checked: false },
            { id: 'txnb', label: 'N.D. Texas', checked: false },
            { id: 'txsb', label: 'S.D. Texas', checked: false },
            { id: 'txwb', label: 'W.D. Texas', checked: false },
            { id: 'utb', label: 'D. Utah', checked: false },
            { id: 'vtb', label: 'D. Vermont', checked: false },
            { id: 'vaeb', label: 'E.D. Virginia', checked: false },
            { id: 'vawb', label: 'W.D. Virginia', checked: false },
            { id: 'waeb', label: 'E.D. Washington', checked: false },
            { id: 'wawb', label: 'W.D. Washington', checked: false },
            { id: 'wvnb', label: 'N.D. West Virginia', checked: false },
            { id: 'wvsb', label: 'S.D. West Virginia', checked: false },
            { id: 'wieb', label: 'E.D. Wisconsin', checked: false },
            { id: 'wiwb', label: 'W.D. Wisconsin', checked: false },
            { id: 'wyb', label: 'D. Wyoming', checked: false },
            { id: 'gub', label: 'D. Guam', checked: false },
            { id: 'nmib', label: 'Northern Mariana Islands', checked: false },
            { id: 'prb', label: 'D. Puerto Rico', checked: false },
            { id: 'vib', label: 'D. Virgin Islands', checked: false },
          ],
        },
      ],
    },
    {
      name: 'State',
      sections: [
        {
          header: 'State Courts',
          courts: [
            { id: 'ala', label: 'Supreme Court of Alabama', checked: false },
            {
              id: 'alactapp',
              label: 'Alabama Court of Appeals (Terminated 1969)',
              checked: false,
            },
            {
              id: 'alacrimapp',
              label: 'Court of Criminal Appeals of Alabama',
              checked: false,
            },
            {
              id: 'alacivapp',
              label: 'Court of Civil Appeals of Alabama',
              checked: false,
            },
            { id: 'alaska', label: 'Alaska Supreme Court', checked: false },
            {
              id: 'alaskactapp',
              label: 'Court of Appeals of Alaska',
              checked: false,
            },
            { id: 'ariz', label: 'Arizona Supreme Court', checked: false },
            {
              id: 'arizctapp',
              label: 'Court of Appeals of Arizona',
              checked: false,
            },
            { id: 'ariztaxct', label: 'Arizona Tax Court', checked: false },
            { id: 'ark', label: 'Supreme Court of Arkansas', checked: false },
            {
              id: 'arkctapp',
              label: 'Court of Appeals of Arkansas',
              checked: false,
            },
            {
              id: 'arkworkcompcom',
              label: "Arkansas Workers' Compensation Commission",
              checked: false,
            },
            {
              id: 'arkag',
              label: 'Arkansas Attorney General Reports',
              checked: false,
            },
            { id: 'cal', label: 'California Supreme Court', checked: false },
            {
              id: 'calctapp',
              label: 'California Court of Appeal',
              checked: false,
            },
            {
              id: 'calappdeptsuper',
              label: 'Appellate Division of the Superior Court of California',
              checked: false,
            },
            {
              id: 'calag',
              label: 'California Attorney General Reports',
              checked: false,
            },
            { id: 'colo', label: 'Supreme Court of Colorado', checked: false },
            {
              id: 'coloctapp',
              label: 'Colorado Court of Appeals',
              checked: false,
            },
            {
              id: 'coloworkcompcom',
              label: 'Colorado Industrial Claim Appeals Office',
              checked: false,
            },
            {
              id: 'coloag',
              label: 'Colorado Attorney General Reports',
              checked: false,
            },
            {
              id: 'conn',
              label: 'Supreme Court of Connecticut',
              checked: false,
            },
            {
              id: 'connappct',
              label: 'Connecticut Appellate Court',
              checked: false,
            },
            {
              id: 'connsuperct',
              label: 'Connecticut Superior Court',
              checked: false,
            },
            {
              id: 'connworkcompcom',
              label: 'Connecticut Compensation Review Board',
              checked: false,
            },
            { id: 'del', label: 'Supreme Court of Delaware', checked: false },
            {
              id: 'delch',
              label: 'Court of Chancery of Delaware',
              checked: false,
            },
            {
              id: 'delorphct',
              label: "Delaware Orphan's Court (Terminated 1970)",
              checked: false,
            },
            {
              id: 'delsuperct',
              label: 'Superior Court of Delaware',
              checked: false,
            },
            {
              id: 'delctcompl',
              label: 'Delaware Court of Common Pleas',
              checked: false,
            },
            { id: 'delfamct', label: 'Delaware Family Court', checked: false },
            {
              id: 'deljudct',
              label: 'Court on the Judiciary of Delaware',
              checked: false,
            },
            {
              id: 'dc',
              label: 'District of Columbia Court of Appeals',
              checked: false,
            },
            { id: 'fla', label: 'Supreme Court of Florida', checked: false },
            {
              id: 'fladistctapp',
              label: 'District Court of Appeal of Florida',
              checked: false,
            },
            {
              id: 'flaag',
              label: 'Florida Attorney General Reports',
              checked: false,
            },
            { id: 'ga', label: 'Supreme Court of Georgia', checked: false },
            {
              id: 'gactapp',
              label: 'Court of Appeals of Georgia',
              checked: false,
            },
            { id: 'haw', label: 'Hawaii Supreme Court', checked: false },
            {
              id: 'hawapp',
              label: 'Hawaii Intermediate Court of Appeals',
              checked: false,
            },
            { id: 'idaho', label: 'Idaho Supreme Court', checked: false },
            {
              id: 'idahoctapp',
              label: 'Idaho Court of Appeals',
              checked: false,
            },
            { id: 'ill', label: 'Illinois Supreme Court', checked: false },
            {
              id: 'illappct',
              label: 'Appellate Court of Illinois',
              checked: false,
            },
            { id: 'ind', label: 'Indiana Supreme Court', checked: false },
            {
              id: 'indctapp',
              label: 'Indiana Court of Appeals',
              checked: false,
            },
            { id: 'indtc', label: 'Indiana Tax Court', checked: false },
            { id: 'iowa', label: 'Supreme Court of Iowa', checked: false },
            {
              id: 'iowactapp',
              label: 'Court of Appeals of Iowa',
              checked: false,
            },
            { id: 'kan', label: 'Supreme Court of Kansas', checked: false },
            {
              id: 'kanctapp',
              label: 'Court of Appeals of Kansas',
              checked: false,
            },
            {
              id: 'kanag',
              label: 'Kansas Attorney General Reports',
              checked: false,
            },
            { id: 'ky', label: 'Kentucky Supreme Court', checked: false },
            {
              id: 'kyctapp',
              label: 'Court of Appeals of Kentucky',
              checked: false,
            },
            {
              id: 'la',
              label: 'Supreme Court of Louisiana',
              checked: false,
            },
            {
              id: 'lactapp',
              label: 'Louisiana Court of Appeal',
              checked: false,
            },
            {
              id: 'laag',
              label: 'Louisiana Attorney General Reports',
              checked: false,
            },
            {
              id: 'me',
              label: 'Supreme Judicial Court of Maine',
              checked: false,
            },
            {
              id: 'md',
              label: 'Court of Appeals of Maryland',
              checked: false,
            },
            {
              id: 'mdctspecapp',
              label: 'Court of Special Appeals of Maryland',
              checked: false,
            },
            {
              id: 'mdag',
              label: 'Maryland Attorney General Reports',
              checked: false,
            },
            {
              id: 'mass',
              label: 'Massachusetts Supreme Judicial Court',
              checked: false,
            },
            {
              id: 'massappct',
              label: 'Massachusetts Appeals Court',
              checked: false,
            },
            {
              id: 'masssuperct',
              label: 'Massachusetts Superior Court',
              checked: false,
            },
            {
              id: 'massdistct',
              label: 'Massachusetts District Court',
              checked: false,
            },
            {
              id: 'masslandct',
              label: 'Massachusetts Land Court',
              checked: false,
            },
            {
              id: 'maworkcompcom',
              label: 'Massachusetts Department of Industrial Accidents',
              checked: false,
            },
            { id: 'mich', label: 'Michigan Supreme Court', checked: false },
            {
              id: 'michctapp',
              label: 'Michigan Court of Appeals',
              checked: false,
            },
            {
              id: 'minn',
              label: 'Supreme Court of Minnesota',
              checked: false,
            },
            {
              id: 'minnctapp',
              label: 'Court of Appeals of Minnesota',
              checked: false,
            },
            {
              id: 'minnag',
              label: 'Minnesota Attorney General Reports',
              checked: false,
            },
            { id: 'miss', label: 'Mississippi Supreme Court', checked: false },
            {
              id: 'missctapp',
              label: 'Court of Appeals of Mississippi',
              checked: false,
            },
            { id: 'mo', label: 'Supreme Court of Missouri', checked: false },
            {
              id: 'moctapp',
              label: 'Missouri Court of Appeals',
              checked: false,
            },
            {
              id: 'moag',
              label: 'Missouri Attorney General Reports',
              checked: false,
            },
            { id: 'mont', label: 'Montana Supreme Court', checked: false },
            { id: 'monttc', label: 'Montana Tax Appeal Board', checked: false },
            {
              id: 'montag',
              label: 'Montana Attorney General Reports',
              checked: false,
            },
            { id: 'neb', label: 'Nebraska Supreme Court', checked: false },
            {
              id: 'nebctapp',
              label: 'Nebraska Court of Appeals',
              checked: false,
            },
            {
              id: 'nebag',
              label: 'Nebraska Attorney General Reports',
              checked: false,
            },
            { id: 'nev', label: 'Nevada Supreme Court', checked: false },
            {
              id: '',
              label: 'Court of Appeals of Nevada',
              checked: false,
            },
            {
              id: 'nh',
              label: 'Supreme Court of New Hampshire',
              checked: false,
            },
            {
              id: 'nj',
              label: 'Supreme Court of New Jersey',
              checked: false,
            },
            {
              id: 'njsuperctappdiv',
              label: 'New Jersey Superior Court App Division',
              checked: false,
            },
            { id: 'njtaxct', label: 'New Jersey Tax Court', checked: false },
            {
              id: 'njch',
              label: 'New Jersey Court of Chancery (Terminated 1947)',
              checked: false,
            },
            { id: 'nm', label: 'New Mexico Supreme Court', checked: false },
            {
              id: 'nmctapp',
              label: 'New Mexico Court of Appeals',
              checked: false,
            },
            { id: 'ny', label: 'New York Court of Appeals', checked: false },
            {
              id: 'nyappdiv',
              label: 'Appellate Division of the Supreme Court of New York',
              checked: false,
            },
            {
              id: 'nyappterm',
              label: 'Appellate Terms of the Supreme Court of New York',
              checked: false,
            },
            { id: 'nysupct', label: 'New York Supreme Court', checked: false },
            { id: '', label: 'New York County Court', checked: false },
            {
              id: '',
              label: 'New York District Court',
              checked: false,
            },
            {
              id: '',
              label: 'New York Town and Village Courts',
              checked: false,
            },
            { id: 'nyfamct', label: 'New York Family Court', checked: false },
            {
              id: 'nysurct',
              label: "New York Surrogate's Court",
              checked: false,
            },
            {
              id: 'nycivct',
              label: 'Civil Court of the City of New York',
              checked: false,
            },
            {
              id: 'nycrimct',
              label: 'Criminal Court of the City of New York',
              checked: false,
            },
            {
              id: 'nyag',
              label: 'New York Attorney General Reports',
              checked: false,
            },
            {
              id: 'nc',
              label: 'Supreme Court of North Carolina',
              checked: false,
            },
            {
              id: 'ncctapp',
              label: 'Court of Appeals of North Carolina',
              checked: false,
            },
            {
              id: 'ncsuperct',
              label: 'Superior Court of North Carolina',
              checked: false,
            },
            {
              id: 'ncworkcompcom',
              label: 'North Carolina Industrial Commission',
              checked: false,
            },
            {
              id: 'nd',
              label: 'North Dakota Supreme Court',
              checked: false,
            },
            {
              id: 'ndctapp',
              label: 'North Dakota Court of Appeals',
              checked: false,
            },
            { id: 'ohio', label: 'Ohio Supreme Court', checked: false },
            { id: 'ohioctapp', label: 'Ohio Court of Appeals', checked: false },
            { id: 'ohioctcl', label: 'Ohio Court of Claims', checked: false },
            { id: 'okla', label: 'Supreme Court of Oklahoma', checked: false },
            {
              id: 'oklacivapp',
              label: 'Court of Civil Appeals of Oklahoma',
              checked: false,
            },
            {
              id: 'oklacrimapp',
              label: 'Court of Criminal Appeals of Oklahoma',
              checked: false,
            },
            {
              id: 'oklajeap',
              label: 'Oklahoma Judicial Ethics Advisory Panel',
              checked: false,
            },
            {
              id: 'oklacoj',
              label: 'Court on the Judiciary of Oklahoma',
              checked: false,
            },
            {
              id: 'oklaag',
              label: 'Oklahoma Attorney General Reports',
              checked: false,
            },
            { id: 'or', label: 'Oregon Supreme Court', checked: false },
            {
              id: 'orctapp',
              label: 'Court of Appeals of Oregon',
              checked: false,
            },
            { id: 'ortc', label: 'Oregon Tax Court', checked: false },
            {
              id: 'pa',
              label: 'Supreme Court of Pennsylvania',
              checked: false,
            },
            {
              id: 'pasuperct',
              label: 'Superior Court of Pennsylvania',
              checked: false,
            },
            {
              id: 'pacommwct',
              label: 'Commonwealth Court of Pennsylvania',
              checked: false,
            },
            {
              id: 'cjdpa',
              label: 'Judicial Discipline of Pennsylvania',
              checked: false,
            },
            {
              id: 'ri',
              label: 'Supreme Court of Rhode Island',
              checked: false,
            },
            {
              id: 'risuperct',
              label: 'Superior Court of Rhode Island',
              checked: false,
            },
            {
              id: 'sc',
              label: 'Supreme Court of South Carolina',
              checked: false,
            },
            {
              id: 'scctapp',
              label: 'Court of Appeals of South Carolina',
              checked: false,
            },
            {
              id: 'sd',
              label: 'South Dakota Supreme Court',
              checked: false,
            },
            { id: 'tenn', label: 'Tennessee Supreme Court', checked: false },
            {
              id: 'tennctapp',
              label: 'Court of Appeals of Tennessee',
              checked: false,
            },
            {
              id: 'tenncrimapp',
              label: 'Court of Criminal Appeals of Tennessee',
              checked: false,
            },
            {
              id: 'tennworkcompcl',
              label: "Tennessee Court of Workers' Comp. Claims",
              checked: false,
            },
            {
              id: 'tennworkcompapp',
              label: "Tennessee Workers' Comp. Appeals Board",
              checked: false,
            },
            {
              id: 'tennsuperct',
              label:
                'Tennessee Superior Court for Law and Equity (Terminated 1809)',
              checked: false,
            },
            { id: 'tex', label: 'Texas Supreme Court', checked: false },
            {
              id: 'texapp',
              label: 'Court of Appeals of Texas',
              checked: false,
            },
            {
              id: 'texcrimapp',
              label: 'Court of Criminal Appeals of Texas',
              checked: false,
            },
            {
              id: 'texreview',
              label: 'Texas Special Court of Review',
              checked: false,
            },
            {
              id: 'texjpml',
              label: 'Texas Judicial Panel on Multidistrict Litigation',
              checked: false,
            },
            {
              id: 'texag',
              label: 'Texas Attorney General Reports',
              checked: false,
            },
            { id: 'utah', label: 'Utah Supreme Court', checked: false },
            {
              id: 'utahctapp',
              label: 'Court of Appeals of Utah',
              checked: false,
            },
            { id: 'vt', label: 'Supreme Court of Vermont', checked: false },
            {
              id: 'vtsuperct',
              label: 'Vermont Superior Court',
              checked: false,
            },
            { id: 'va', label: 'Supreme Court of Virginia', checked: false },
            {
              id: 'vactapp',
              label: 'Court of Appeals of Virginia',
              checked: false,
            },
            { id: 'wash', label: 'Washington Supreme Court', checked: false },
            {
              id: 'washctapp',
              label: 'Court of Appeals of Washington',
              checked: false,
            },
            {
              id: 'washag',
              label: 'Washington Attorney General Reports',
              checked: false,
            },
            {
              id: 'washterr',
              label: 'Washington Territory (Terminated 1889)',
              checked: false,
            },
            {
              id: 'wva',
              label: 'West Virginia Supreme Court',
              checked: false,
            },
            {
              id: '',
              label: 'Int. Ct. of App. of W.Va.',
              checked: false,
            },
            { id: 'wis', label: 'Wisconsin Supreme Court', checked: false },
            {
              id: 'wisctapp',
              label: 'Court of Appeals of Wisconsin',
              checked: false,
            },
            {
              id: 'wisag',
              label: 'Wisconsin Attorney General Reports',
              checked: false,
            },
            { id: 'wyo', label: 'Wyoming Supreme Court', checked: false },
          ],
        },
        {
          header: 'U.S. Territory Courts',
          courts: [
            { id: 'guam', label: 'Supreme Court of Guam', checked: false },
            {
              id: '',
              label: 'Supreme Court of the Comm. of the N. Mariana Islands',
              checked: false,
            },
            {
              id: '',
              label: 'Northern Mariana Islands Commonwealth Superior Court',
              checked: false,
            },
            {
              id: '',
              label: 'Northern Mariana Islands Commonwealth Trial Court',
              checked: false,
            },
            {
              id: '',
              label: 'Supreme Court of Puerto Rico',
              checked: false,
            },
            {
              id: '',
              label:
                'Tribunal De Apelaciones De Puerto Rico/Court of Appeals of Puerto Rico',
              checked: false,
            },
            {
              id: '',
              label: 'Supreme Court of The Virgin Islands',
              checked: false,
            },
            {
              id: '',
              label: 'High Court of American Samoa',
              checked: false,
            },
            {
              id: '',
              label: 'American Samoa District Court',
              checked: false,
            },
          ],
        },
      ],
    },
    {
      name: 'Other Legal Entities',
      sections: [
        {
          header: 'Military Courts',
          courts: [
            {
              id: 'afcca',
              label: 'Air Force Court of Criminal Appeals',
              checked: false,
            },
            {
              id: '',
              label: 'U.S Air Force Court of Military Review',
              checked: false,
            },
            {
              id: 'armfor',
              label: 'Court of Appeals for the Armed Forces',
              checked: false,
            },
            {
              id: '',
              label: 'United States Court of Military Appeals',
              checked: false,
            },
            {
              id: 'acca',
              label: 'Army Court of Criminal Appeals',
              checked: false,
            },
            {
              id: '',
              label: 'U.S. Army Court of Military Review',
              checked: false,
            },
            {
              id: '',
              label: 'U.S Coast Guard Court of Criminal Appeals',
              checked: false,
            },
            {
              id: '',
              label: 'U.S Coast Guard Court of Military Review',
              checked: false,
            },
            { id: 'mc', label: 'Military Commission Review', checked: false },
            {
              id: 'nmcca',
              label: 'Navy-Marine Corps Court of Criminal Appeals',
              checked: false,
            },
            {
              id: '',
              label: 'U.S. Navy-Marine Corps Court of Military Review',
              checked: false,
            },
          ],
        },
        {
          header: 'Tribal Courts',
          courts: [
            {
              id: '',
              label: 'Cherokee Nation Supreme Court',
              checked: false,
            },
            {
              id: '',
              label: 'Cherokee Nation Judicial Appeals Tribunal',
              checked: false,
            },
            {
              id: '',
              label: 'Cherokee Indian Trib. Ct.',
              checked: false,
            },
            {
              id: '',
              label: 'Cheyenne River Sioux Tribal Court of Appeals',
              checked: false,
            },
            {
              id: '',
              label: 'Colville Confederated Court of Appeals',
              checked: false,
            },
            {
              id: '',
              label: 'Coquille Indian Trial Court',
              checked: false,
            },
            {
              id: '',
              label: 'Eastern Band of Cherokee Indians Supreme Court',
              checked: false,
            },
            {
              id: '',
              label: 'Eastern Band of Cherokee Indians Tribal Court of Appeals',
              checked: false,
            },
            {
              id: '',
              label: 'Fort McDowell Yavapai Nation Tribal Court of Appeals',
              checked: false,
            },
            {
              id: '',
              label: 'Fort McDowell Supreme Court',
              checked: false,
            },
            {
              id: '',
              label: 'Fort Peck Appellate Court',
              checked: false,
            },
            {
              id: '',
              label: 'Fort Peck Tribal Court',
              checked: false,
            },
            {
              id: '',
              label: 'Grand Ronde Court of Appeals',
              checked: false,
            },
            {
              id: '',
              label: 'Grand Ronde Tribal Court',
              checked: false,
            },
            {
              id: '',
              label:
                'Grand Traverse Band of Ottawa & Chippewa Indians Tribal Appellate Court',
              checked: false,
            },
            {
              id: '',
              label:
                'Grand Traverse Band of Ottawa and Chippewa Indians Tribal Court',
              checked: false,
            },
            {
              id: '',
              label: 'Ho-Chunk Nation Supreme Court',
              checked: false,
            },
            {
              id: '',
              label: 'Ho-Chunk Nation Trial Court',
              checked: false,
            },
            { id: '', label: 'Hopi Appellate Court', checked: false },
            {
              id: '',
              label: 'Leech Lake Band of Ojibwe Tribal Court',
              checked: false,
            },
            {
              id: '',
              label:
                'Little River Band of Ottawa Indians Tribal Court of Appeals',
              checked: false,
            },
            {
              id: '',
              label: 'Little River Band of Ottawa Indians Tribal Court',
              checked: false,
            },
            {
              id: '',
              label: 'Mohegan Trial Court',
              checked: false,
            },
            {
              id: '',
              label: 'Mohegan Gaming Disputes Trial Court',
              checked: false,
            },
            {
              id: '',
              label: 'Mohegan Gaming Disputes Court of Appeals',
              checked: false,
            },
            {
              id: '',
              label: 'Council of Elders of the Mohegan Tribe',
              checked: false,
            },
            {
              id: '',
              label: 'Navajo Nation Supreme Court',
              checked: false,
            },
            {
              id: '',
              label: 'Navajo Nation Ct. App.',
              checked: false,
            },
            {
              id: '',
              label: 'Navajo Nation Family Court',
              checked: false,
            },
            {
              id: '',
              label: 'Navajo Nation Children’s Court',
              checked: false,
            },
            {
              id: '',
              label: 'Oneida Appellate Court',
              checked: false,
            },
            {
              id: '',
              label: 'Oneida Tribal Judicial System, Trial Court',
              checked: false,
            },
            {
              id: '',
              label: 'Sac and Fox Nation Supreme Court',
              checked: false,
            },
            {
              id: '',
              label: 'Sac and Fox Nation District Court',
              checked: false,
            },
            {
              id: '',
              label: 'Confederated Salish & Kootenai Court of Appeals',
              checked: false,
            },
            {
              id: '',
              label: 'Shoshone and Arapaho Tribal Court',
              checked: false,
            },
            {
              id: '',
              label: 'Swinomish Tribal Court',
              checked: false,
            },
            {
              id: '',
              label: 'Swinomish Tribal Court of Appeals',
              checked: false,
            },
            {
              id: '',
              label: 'Tulalip Court of Appeals',
              checked: false,
            },
            {
              id: '',
              label: 'White Earth Band of Chippewa Tribal Court',
              checked: false,
            },
          ],
        },
        {
          header: 'Miscellaneous Courts',
          courts: [
            { id: 'ag', label: 'Attorneys General', checked: false },
            {
              id: 'asbca',
              label: 'Armed Services Board of Contract Appeals',
              checked: false,
            },
            { id: 'uscfc', label: 'Federal Claims', checked: false },
            { id: 'tax', label: 'U.S. Tax Court', checked: false },
            {
              id: 'bia',
              label: 'Board of Immigration Appeals',
              checked: false,
            },
            { id: 'olc', label: 'Office of Legal Counsel', checked: false },
            {
              id: 'mspb',
              label: 'Merit Systems Protection Board',
              checked: false,
            },
            { id: 'cavc', label: 'Veterans Claims', checked: false },
            { id: 'bva', label: "Board of Veterans' Appeals", checked: false },
            {
              id: 'fiscr',
              label: 'Foreign Intelligence Surveillance Court of Review',
              checked: false,
            },
            {
              id: 'fisc',
              label: 'Foreign Intelligence Surveillance Court',
              checked: false,
            },
            {
              id: 'cit',
              label: 'Court of International Trade',
              checked: false,
            },
            {
              id: 'usjc',
              label: 'U.S. Judicial Conference Committee',
              checked: false,
            },
            {
              id: 'jpml',
              label: 'Judicial Panel on Multidistrict Litigation',
              checked: false,
            },
          ],
        },
        {
          header: 'Historical Courts',
          courts: [
            {
              id: 'cc',
              label: 'Court of Claims (1992)',
              checked: false,
            },
            {
              id: 'com',
              label: 'Commerce Court (1913)',
              checked: false,
            },
            {
              id: 'ccpa',
              label: 'Customs and Patent Appeals (1982)',
              checked: false,
            },
            {
              id: 'cusc',
              label: 'U.S. Customs Court (1980)',
              checked: false,
            },
            {
              id: 'bta',
              label: 'Board of Tax Appeals (1942)',
              checked: false,
            },
            {
              id: 'eca',
              label: 'Emergency Court of Appeals (1962)',
              checked: false,
            },
            {
              id: 'tecoa',
              label: 'Temporary Emergency Court of Appeals (1992)',
              checked: false,
            },
            {
              id: 'reglrailreorgct',
              label:
                'Special Court under the Regional Rail Reorganization Act (1996)',
              checked: false,
            },
            {
              id: 'kingsbench',
              label: "Court of King's Bench (1873)",
              checked: false,
            },
          ],
        },
      ],
    },
  ];

  selectedDistrict = this.districts[0];

  constructor(private renderer: Renderer2, private elRef: ElementRef) {}

  ngOnInit(): void {
    document.addEventListener('wheel', this.preventZoom, { passive: false });
    document.addEventListener('keydown', this.preventZoomKeys, {
      passive: false,
    });

    const savedCourts = localStorage.getItem('selectedCourts');
    const savedDateRange = localStorage.getItem('dateRange');

    if (savedCourts) {
      this.selectedCourts = JSON.parse(savedCourts);
      this.selectedCourtsBackup = [...this.selectedCourts];

      // Remove invalid selected courts that no longer exist
      this.selectedCourts = this.selectedCourts.filter((courtId: string) =>
        this.districts.some((district) =>
          district.sections.some((section) =>
            section.courts.some((court) => court.id === courtId)
          )
        )
      );

      // Update the UI for selected courts
      this.districts.forEach((district) => {
        district.sections.forEach((section) => {
          section.courts.forEach((court) => {
            court.checked = this.selectedCourts.includes(court.id);
          });
        });
      });
    } else {
      this.checkAllCourts();
    }

    // if (savedDateRange) {
    //   this.dateRange = JSON.parse(savedDateRange);
    //   this.dateRangeBackup = { ...this.dateRange };
    // }

    this.clickListener = this.renderer.listen('document', 'click', (event) =>
      this.onClickOutside(event)
    );
  }

  ngOnDestroy(): void {
    document.removeEventListener('wheel', this.preventZoom);
    document.removeEventListener('keydown', this.preventZoomKeys);

    if (this.clickListener) {
      this.clickListener();
    }
  }

  // Detect if the click was outside the popup-content container
  onClickOutside(event: MouseEvent) {
    const popupContent =
      this.elRef.nativeElement.querySelector('.popup-content');
    const overlay = this.elRef.nativeElement.querySelector('.popup-overlay');

    // If the click happened outside the popup-content and on the overlay, trigger onCancel()
    if (overlay && !popupContent.contains(event.target)) {
      this.onCancel();
    }
  }

  applyChanges() {
    this.selectedCourtsChanged.emit(this.selectedCourts);
    // this.dateRangeChanged.emit(this.dateRange);
    localStorage.setItem('selectedCourts', JSON.stringify(this.selectedCourts));
    // localStorage.setItem('dateRange', JSON.stringify(this.dateRange));
  }

  cancelChanges() {
    this.selectedCourts = [...this.selectedCourtsBackup];
    // this.dateRange = { ...this.dateRangeBackup };
  }

  onDone() {
    this.applyChanges();
    this.onHandlePopup();
  }

  onCancel() {
    this.cancelChanges();
    this.onHandlePopup();
  }

  selectDistrict(district: any) {
    this.selectedDistrict = district;
  }

  onCourtCheckboxChange(event: Event) {
    const checkbox = event.target as HTMLInputElement;
    const courtShortname = checkbox.id;

    if (checkbox.checked) {
      if (!this.selectedCourts.includes(courtShortname)) {
        this.selectedCourts.push(courtShortname);
      }
    } else {
      this.selectedCourts = this.selectedCourts.filter(
        (name) => name !== courtShortname
      );
    }
    // this.selectedCourtsChanged.emit(this.selectedCourts);
  }

  // onDateRangeChange(dateRange: { from: number | null; to: number | null }) {
  //   this.dateRange = dateRange;
  // }

  checkAllCourts() {
    this.districts.forEach((district) => {
      district.sections.forEach((section) => {
        section.courts.forEach((court) => {
          if (!court.checked) {
            court.checked = true;
            this.selectedCourts.push(court.id);
          }
        });
      });
    });
    // this.selectedCourtsChanged.emit(this.selectedCourts);
  }

  clearAllCourts() {
    this.districts.forEach((district) => {
      district.sections.forEach((section) => {
        section.courts.forEach((court) => {
          court.checked = false;
        });
      });
    });
    this.selectedCourts = [];
    // this.selectedCourtsChanged.emit(this.selectedCourts);
  }

  preventZoom(event: WheelEvent): void {
    if (event.ctrlKey) {
      event.preventDefault();
    }
  }

  preventZoomKeys(event: KeyboardEvent): void {
    if (
      event.ctrlKey &&
      (event.key === '+' || event.key === '-' || event.key === '0')
    ) {
      event.preventDefault();
    }
  }
}
