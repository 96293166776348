import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

export interface SubscriptionStatus {
  hasSubscription: boolean;
  accessUntil: Date | null;
  pendingCancellation: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  private subscriptionStatusSubject = new BehaviorSubject<SubscriptionStatus>({
    hasSubscription: false,
    accessUntil: null,
    pendingCancellation: false,
  });

  subscriptionStatus$ = this.subscriptionStatusSubject.asObservable();

  constructor(private apiService: ApiService) {}

  // Fetch subscription status and process it
  loadSubscriptionStatus(): Observable<SubscriptionStatus> {
    return this.apiService.checkSubscription().pipe(
      map((response) => {
        let accessUntil: Date | null = null;
        if (response.accessUntil) {
          accessUntil = new Date(response.accessUntil);
        }

        const now = new Date();
        const hasSubscription: boolean =
          response.isSubscribed && accessUntil !== null && accessUntil > now;

        const pendingCancellation: boolean =
          response.cancelAtPeriodEnd || false;

        return {
          hasSubscription,
          accessUntil,
          pendingCancellation,
        };
      }),
      tap((status) => this.subscriptionStatusSubject.next(status)),
      catchError((err) => {
        console.error('Failed to load subscription status:', err);
        const fallbackStatus: SubscriptionStatus = {
          hasSubscription: false,
          accessUntil: null,
          pendingCancellation: false,
        };
        this.subscriptionStatusSubject.next(fallbackStatus);
        return of(fallbackStatus);
      })
    );
  }

  // Expose the current subscription status
  getCurrentStatus(): SubscriptionStatus {
    return this.subscriptionStatusSubject.getValue();
  }
}
