// shared.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root', // Makes the service available app-wide
})
export class SharedService {
  private filterVisibilitySubject = new BehaviorSubject<boolean>(false);
  private comparePopupVisibilitySubject = new BehaviorSubject<boolean>(false);

  filterVisibility$ = this.filterVisibilitySubject.asObservable();
  comparePopupVisibility$ = this.comparePopupVisibilitySubject.asObservable();

  constructor() {}

  toggleFilterVisibility() {
    this.filterVisibilitySubject.next(!this.filterVisibilitySubject.value);
  }
  toggleComparePopupVisibility() {
    this.comparePopupVisibilitySubject.next(
      !this.comparePopupVisibilitySubject.value
    );
  }
}
