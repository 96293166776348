<div class="popup-overlay">
  <div class="popup-content container overflow-auto">
    <div
      class="container mt-3 p-3 border rounded shadow-sm"
      style="background-color: #202020"
    >
      <div class="d-flex justify-content-between align-items-center mb-3">
        <h5 class="m-0" style="color: #f8f9fa">Jurisdictions</h5>
        <div>
          <button
            (click)="checkAllCourts()"
            style="text-decoration: none"
            class="btn btn-link p-1 me-2"
          >
            Check All
          </button>
          <button
            (click)="clearAllCourts()"
            style="text-decoration: none"
            class="btn btn-link p-1 me-2"
          >
            Clear All
          </button>
        </div>
      </div>

      <!-- District Tabs -->
      <ul class="nav nav-tabs mb-3">
        <li class="nav-item" *ngFor="let district of districts">
          <a
            class="nav-link"
            [class.active]="selectedDistrict === district"
            (click)="selectDistrict(district)"
            href="javascript:void(0);"
          >
            {{ district.name }}
          </a>
        </li>
      </ul>

      <div *ngIf="selectedDistrict">
        <h5 class="text-light mt-3">{{ selectedDistrict.name }}</h5>
        <div *ngFor="let section of selectedDistrict.sections">
          <h6 class="text-light mt-3" *ngIf="section.header">
            {{ section.header }}
          </h6>
          <div class="row g-2">
            <div class="col-6 col-md-3" *ngFor="let court of section.courts">
              <div class="form-check">
                <input
                  type="checkbox"
                  class="form-check-input"
                  [id]="court.id"
                  [checked]="court.checked"
                  (change)="onCourtCheckboxChange($event)"
                />
                <!-- [(ngModel)]="court.checked" -->
                <label class="form-check-label" [for]="court.id">{{
                  court.label
                }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <app-date-range-courts
        (dateRangeChanged)="onDateRangeChange($event)"
      ></app-date-range-courts> -->

      <div class="d-flex justify-content-end mt-3">
        <button (click)="onCancel()" class="btn btn-secondary me-2">
          Cancel
        </button>
        <button (click)="onDone()" class="btn btn-secondary me-2">Done</button>
      </div>
    </div>
  </div>
</div>
