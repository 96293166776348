<div class="fade-in container">
  <div
    class="custom-height row w-100 justify-content-center align-self-center vh-100"
  >
    <!-- Free Tier -->
    <div class="col-md-6 col-lg-4 mb-4 align-self-center">
      <div
        class="pricing-card card shadow-sm border-0"
        style="border-radius: 15px"
      >
        <div class="card-body text-center">
          <h5 class="card-title mb-4">Free</h5>
          <h2 class="display-4 fw-bold">Free</h2>
          <p>Limited Features</p>
          <p class="mb-4">Access to essential features with limited usage</p>

          <!-- Show Downgrade Button only if not pending cancellation -->
          <button
            *ngIf="
              subscriptionStatus.hasSubscription &&
              !subscriptionStatus.pendingCancellation
            "
            (click)="confirmDowngrade()"
            class="btn btn-secondary btn-lg mb-4"
          >
            Downgrade
          </button>

          <!-- Show disabled downgrade button -->
          <button
            disabled
            *ngIf="subscriptionStatus.pendingCancellation"
            class="btn btn-secondary btn-lg mb-4"
          >
            Downgrade
          </button>

          <!-- Show disabled your current plan button -->
          <button
            disabled
            *ngIf="
              !subscriptionStatus.hasSubscription &&
              !subscriptionStatus.pendingCancellation
            "
            class="btn btn-secondary btn-lg mb-4"
          >
            Your current plan
          </button>

          <ul class="text-start">
            <li class="mb-2">
              <i class="bi bi-check-circle-fill text-success"></i> Access to
              chat
            </li>
            <li class="mb-2">
              <i class="bi bi-check-circle-fill text-success"></i> Access to
              message history
            </li>
            <li class="mb-2">
              <i class="bi bi-check-circle-fill text-success"></i> Compare cases
              (limited)
            </li>
            <li class="mb-2">
              <i class="bi bi-check-circle-fill text-success"></i> Chat about
              specific documents (limited)
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- Plus Tier -->
    <div class="col-md-6 col-lg-4 mb-4 align-self-center">
      <div
        class="pricing-card card shadow-sm border-0"
        style="border-radius: 15px"
      >
        <div class="card-body text-center">
          <h5 class="card-title mb-4">Plus</h5>
          <h2 class="display-4 fw-bold">$49.99</h2>
          <p>USD/month</p>
          <p class="mb-4">Unlock unlimited access to all features</p>

          <!-- Show Upgrade Button only if not subscribed -->
          <button
            *ngIf="!subscriptionStatus.hasSubscription"
            [routerLink]="'/payment'"
            class="btn btn-primary btn-lg mb-4"
          >
            Upgrade Now!
          </button>

          <!-- Show Disabled Current Plan Button -->
          <button
            disabled
            *ngIf="
              subscriptionStatus.hasSubscription &&
              !subscriptionStatus.pendingCancellation
            "
            class="btn btn-secondary btn-lg mb-4"
          >
            Your current plan
          </button>

          <p
            *ngIf="
              subscriptionStatus.hasSubscription &&
              subscriptionStatus.pendingCancellation
            "
            class="text-warning"
          >
            Subscription pending cancellation. Expires on
            {{ subscriptionStatus.accessUntil | date : "longDate" }}.
          </p>

          <ul class="text-start">
            <li class="mb-2">
              <i class="bi bi-check-circle-fill text-success"></i> Access to
              chat
            </li>
            <li class="mb-2">
              <i class="bi bi-check-circle-fill text-success"></i> Access to
              message history
            </li>
            <li class="mb-2">
              <i class="bi bi-check-circle-fill text-success"></i> Unlimited
              case comparisons
            </li>
            <li class="mb-2">
              <i class="bi bi-check-circle-fill text-success"></i> Chat about
              specific documents
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Popup -->
<div *ngIf="showPopup">
  <app-payment-popup
    *ngIf="popupConfig"
    [message]="popupConfig?.message || ''"
    [countdown]="popupConfig?.countdown ?? null"
    [redirectPath]="popupConfig?.redirectPath || ''"
    [confirmAction]="popupConfig?.confirmAction"
  ></app-payment-popup>
</div>
