import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrl: './pricing.component.css',
})
export class PricingComponent {
  constructor(private router: Router) {}

  navigateToChat(): void {
    this.router.navigate(['/chat']);
  }

  navigateToPayment(): void {
    this.router.navigate(['/payment']);
  }
}
