import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import {
  SubscriptionService,
  SubscriptionStatus,
} from '../../services/subscription.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css'],
})
export class AccountComponent implements OnInit {
  user: any;
  subscriptionStatus: SubscriptionStatus = {
    hasSubscription: false,
    accessUntil: null,
    pendingCancellation: false,
  };

  constructor(
    public authService: AuthService,
    private subscriptionService: SubscriptionService
  ) {}

  ngOnInit(): void {
    // Fetch user details
    this.user = this.authService.getUserFromLocalStorage();
    console.log('(account) user: ', this.user);

    // Fetch subscription status
    this.subscriptionService.loadSubscriptionStatus().subscribe({
      next: (status) => {
        this.subscriptionStatus = status;
        console.log('Subscription status (account):', this.subscriptionStatus);
      },
      error: (err) => {
        console.error('Error fetching subscription status (account):', err);
      },
    });
  }

  logout(): void {
    this.authService.logout();
    this.user = null;
  }
}
